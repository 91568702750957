














































































import configMixin from '@/mixins/chatConfig'
import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import NotAvailableOptionsOverlay from '@/components/NotAvailableOptionsOverlay.vue'
import { getTariffTranslationKey } from '@/includes/helpers/tariffHelper'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import { InputSetups } from '../../mixins/input-setups'
import BrandBotSettings from '@/components/chat/BrandBotSettings/BrandBotSettings.vue'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'

import { mapActions, mapGetters } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import { Component, Mixins } from 'vue-property-decorator'
import ExtendedHelpMessage from 'piramis-base-components/src/components/ExtendedHelpMessage.vue'

@Component({
  components: {
    BrandBotSettings,
    ValidationObserver,
    NotAvailableOptionsOverlay,
    CenteredColumnLayout,
    PageTitle,
    ExtendedHelpMessage
  },
  data() {
    return {
      isPopupOpen: false,
    }
  },
  mixins: [ configMixin ],
  computed: {
    ...mapGetters([
      'getHaveLicense',
      'getHaveBrand',
      'isChatLicenseExists'
    ]),
  },
  methods: {
    getTariffTranslationKey,
    ...mapActions([
      'setChatBrand',
    ]),
  },
})
export default class Branding extends Mixins(UseFields, InputSetups, TariffsTagsHelper) {
  form = {
    brand_bot_token: ''
  }

  isAddBrandBotTutorialOpen = false

  mounted() {
    this.$baseTemplate.saveButton.hide()
  }
}
